var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget recent-posts-entry recent-accommodation"},[_c('h2',{staticClass:"widget-title text-uppercase"},[_vm._v(_vm._s(_vm.$t('Exclusivités'))+" ")]),_vm._v(" "),_c('div',{staticClass:"section-content"},_vm._l((_vm.data),function(value,index){return _c('div',{key:index,staticClass:"widget-post-bx"},[_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({
                    name: _vm.getRoute(value.nature)
                    , params: {
                        category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.type.slug, 'accommodationType')
                        ,slug : _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.slug, 'accommodation')
                    }
                })}},[_c('div',{staticClass:"widget-post clearfix"},[_c('div',{staticClass:"wt-post-media _lz-loading _ratio-container _unknown-ratio-container"},[_c('img',{staticClass:"_lazyload",attrs:{"width":_vm.$getImageSizeByFilterSets('width', 'mini_thumbnail'),"height":_vm.$getImageSizeByFilterSets('height','mini_thumbnail'),"src":_vm.imagePath(value.primaryImage),"alt":_vm.imageName(value.primaryImage)}})]),_vm._v(" "),_c('div',{staticClass:"wt-post-info"},[_c('div',{staticClass:"wt-post-header"},[_c('h6',{staticClass:"post-title"},[_vm._v(" "+_vm._s(value.type.name)+" ")])]),_vm._v(" "),_c('div',{staticClass:"wt-post-meta"},[_c('ul',[(value.price > 0)?_c('li',{staticClass:"post-author"},[_c('span',{staticClass:"amount"},[_vm._v(_vm._s(_vm.toCurrencyString(value.price)))])]):_vm._e()])])])])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }